import React from 'react'
import { useRef } from 'react'
import "./Join.css"
import emailjs from '@emailjs/browser'
import { useState } from 'react'

const Join = () => {
    const form = useRef()
    const [val, setVal] = useState("")

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm('service_mpc0ktk', 'template_0ndft3a', form.current, 'CBTaxFAey5d5zm8To')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
    };

    return (
        <div className='Join'>
            <div className='left-j'>
                <div className='tiret'></div>
                <div className='text'>
                    <div><span className='stroke-text'>READY TO</span> LEVEL UP</div>
                    <div> YOUR BODY<span className='stroke-text'> WITH US?</span></div>
                </div>

            </div>
            <div className='right-j'>
                <form ref={form} className="email-container" onSubmit={sendEmail}>
                    <input className='btn-email' name='user_email' type="text" placeholder='Enter your Email address' />
                    <button className='btn'>Join now</button>
                </form>
            </div>
        </div>
    )
}

export default Join