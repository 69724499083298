import React from 'react'
import './Testimony.css'
import { testimonialsData } from "../../data/testimonialsData";
import { useState } from 'react';
import rightArrow from "../../assets/rightArrow.png";
import leftArrow from "../../assets/leftArrow.png";
import { motion } from "framer-motion"

const Testimony = () => {
    const [selected, setSelected] = useState(0)
    const tLenght = testimonialsData.length
    const transition = { type: "spring", duration: 3 }
    return (
        <div className='Testimony'>
            <div className="left-t">
                <span>Testimonials</span>
                <span className='stroke-text'>What they</span>
                <span>Say about us</span>
                <motion.span
                    key={selected}
                    initial={{ opacity: 0, x: -100 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: 100 }}
                    transition={transition}
                >{testimonialsData[selected].review}</motion.span>
                <div className='autor'><span className='autor-name'>{testimonialsData[selected].name}</span><span> - {testimonialsData[selected].status}</span></div>
            </div>
            <div className="right-t">
                <motion.div
                    initial={{ opacity: 0, x: -100 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    transition={{ ...transition, duration: 2 }}
                ></motion.div>
                <motion.div
                    initial={{ opacity: 0, x: 100 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    transition={{ ...transition, duration: 2 }}></motion.div>
                <motion.img
                    key={selected}
                    initial={{ opacity: 0, x: 100 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: -100 }}
                    transition={transition}
                    src={testimonialsData[selected].image} alt="" />
                <div className="Arrows">
                    <img src={leftArrow} alt="" onClick={() => {
                        if (selected > 0) {
                            setSelected((prev) => prev - 1)
                        }
                        else {
                            setSelected(tLenght - 1)
                        }
                    }} />
                    <img src={rightArrow} alt="" onClick={() => {
                        if (selected < tLenght - 1) {
                            setSelected((prev) => prev + 1)
                        }
                        else {
                            setSelected(0)
                        }
                    }} />
                </div>
            </div>
        </div>
    )
}

export default Testimony