import React from 'react'
import "./Footer.css"
import Github from "../../assets/github.png"
import LinkedIn from "../../assets/linkedin.png"
import Instagram from "../../assets/instagram.png"
import Logo from "../../assets/logo.png"

const Footer = () => {
    return (
        <div className="foot-container">
            <hr className='ligne' />
            <div className="blur footer-blur-1"></div>
            <div className="blur footer-blur-2"></div>
            <div className='Footer'>
                <div className='networks'>
                    <img src={Github} alt="" />
                    <img src={Instagram} alt="" />
                    <img src={LinkedIn} alt="" />
                </div>
                <img src={Logo} className={"logo"} alt="" />
            </div>
        </div>

    )
}

export default Footer