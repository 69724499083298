import React from 'react'
import "./Plans.css"
import { plansData } from "../../data/plansData"
import whiteTick from "../../assets/whiteTick.png"

const Plans = () => {
    return (
        <div className='plans-container'>
            <div className="blur plans-blur-1"></div>
            <div className="blur plans-blur-2"></div>
            <div className='plans-header'>
                <span className='stroke-text'>READY TO START</span>
                <span>YOUR JOURNEY</span>
                <span className='stroke-text'>NOW WITHUS</span>
            </div>
            {/* Plans Cards */}
            <div className="plans">
                {plansData.map((plan, index) =>
                (<div className='plan' key={index}>
                    {plan.icon}
                    <span className='plan-name'>{plan.name}</span>
                    <span className='plan-price'>$ {plan.price}</span>
                    <div className='features'>
                        {plan.features.map((f, i) => (
                            <div className="feature">
                                <img src={whiteTick} alt="" />
                                <span>{f}</span>
                            </div>
                        ))}
                    </div>
                    <div><span>See more benefits -></span></div>
                    <button className='btn'>Join Us</button>
                </div>))}
            </div>
        </div>

    )
}

export default Plans